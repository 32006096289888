<template>
  <div>
    <the-toolbar title="Notificações">
      <v-btn icon @click="getNotificacoes">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </the-toolbar>
    <div v-if="loading" class="d-flex justify-center mt-10 pa-10">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <v-container v-else class="container-notificacoes">
      <v-row no-gutters>
        <v-col>
          <p class="text-center ma-2 subtitle-2 grey--text">{{ notificacoes.length }} registro(s) encontrados(s)</p>
        </v-col>
      </v-row>
      <v-row no-gutters class="border-down">
        <v-col cols="12" v-for="(notificacao,index) in notificacoes" :key="index" class="my-5">
          <v-card :color="index % 2 === 0 ? 'primary' : 'grey lighten-4'" :dark="index % 2 === 0 ? true : false" :outlined="true">
            <div class="d-flex pa-5">
              <div class="data text-center mr-4" v-html="formataData(notificacao.dataCadastro)"></div>
              <v-divider vertical></v-divider>
              <div class="ml-4">
                <div class="div-break-word">
                  <h2 class="text-h5">{{notificacao.titulo}}</h2>
                  <h3 class="text-body-1">{{notificacao.resumo}}</h3>
                </div>
                <div class="mt-2 container-image" v-if="notificacao.arquivos && notificacao.arquivos.length > 0" >
                  <img :src="notificacao.arquivos[0].url" alt="imagem-notificação"/>
                </div>    
                <div v-if="notificacao.dataAvaliacao" class="mt-5">
                  <v-icon v-if="notificacao.avaliacao" color="green">mdi-thumb-up-outline</v-icon>
                  <v-icon v-else color="red">mdi-thumb-down-outline</v-icon>
                </div>
                <div v-else class="mt-5">
                  <v-btn color="green" icon @click="onClickAvaliar(notificacao.guid, true)">
                    <v-icon>mdi-thumb-up-outline</v-icon>
                  </v-btn>
                  <v-btn color="red" icon @click="onClickAvaliar(notificacao.guid, false)">
                    <v-icon>mdi-thumb-down-outline</v-icon>
                  </v-btn>
                </div>     
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex';
import TheToolbar from '@/components/TheToolbar.vue';
import comunicacoesService from '@/services/comunicacoesService';

export default {
  name: 'Notificacoes',

  data() {
    return {
      loading: false,
      notificacoes: [],
    };
  },
  components: { TheToolbar },
  created() {
    this.getNotificacoes();
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
    }),
  },
  methods: {
    async getNotificacoes() {
      try {
        this.loading = true;
        this.notificacoes = await comunicacoesService.getNotificacoes();
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao carregar as notificações, tente novamente mais tarde',
        });
      } finally {
        this.loading = false;
      }
    },
    async onClickAvaliar(guid, avaliacao) {
      try {
        await comunicacoesService.avaliarNotificacao(guid, avaliacao);
        this.$root.$snackBar.open({
          color: "success",
          message: "Obrigado pelo feedback!",
        });
        this.getNotificacoes();
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao avaliar a notificação, tente novamente mais tarde.",
        });
      }
    },
    formataData(data) {
      const dia = moment(data).lang('pt').format('DD');
      const mes = moment(data).lang('pt').format('MMM');
      const year = moment(data).lang('pt').format('YYYY');
      const hora = moment(data).lang('pt').format('HH:MM');
      return `
        <div class="data data-dia text-h4">${dia}</div>
        <div class="data data-mes">${mes}</div>
        <div class="data data-ano">${year}</div>
        <div class="data data-hora text-overline">${hora}</div>
        `;
    },
  },
};
</script>
<style lang="scss">
@import '~@/styles/variables';

.data-hora:before {
    content: "\F0954";
    margin: 0px 2px 0 0;
    font: normal normal normal 16px/1 "Material Design Icons";
    position: relative;
    top: 1px;
}

.data-mes {
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1666666667em;
    font-family: "Segoe UI";
}

.data-ano {
    font-size: 0.8rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1666666667em;
    font-family: "Segoe UI";
}

.container-notificacoes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 200px;
  margin-top: 20px;
  @media (max-width: $media-sm) {
    padding: 0 20px;
  }
}

.container-image img {
  max-height: 300px;
  max-width: 400px;
  @media (max-width: $media-sm) {
    max-width: 100%;
  }
}

.div-break-word {
  word-break: break-word;
}

</style>
