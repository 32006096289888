var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "the-toolbar",
        { attrs: { title: "Notificações" } },
        [
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.getNotificacoes } },
            [_c("v-icon", [_vm._v("mdi-reload")])],
            1
          ),
        ],
        1
      ),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _c(
            "v-container",
            { staticClass: "container-notificacoes" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", [
                    _c(
                      "p",
                      { staticClass: "text-center ma-2 subtitle-2 grey--text" },
                      [
                        _vm._v(
                          _vm._s(_vm.notificacoes.length) +
                            " registro(s) encontrados(s)"
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "border-down", attrs: { "no-gutters": "" } },
                _vm._l(_vm.notificacoes, function (notificacao, index) {
                  return _c(
                    "v-col",
                    { key: index, staticClass: "my-5", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            color:
                              index % 2 === 0 ? "primary" : "grey lighten-4",
                            dark: index % 2 === 0 ? true : false,
                            outlined: true,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex pa-5" },
                            [
                              _c("div", {
                                staticClass: "data text-center mr-4",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.formataData(notificacao.dataCadastro)
                                  ),
                                },
                              }),
                              _c("v-divider", { attrs: { vertical: "" } }),
                              _c("div", { staticClass: "ml-4" }, [
                                _c("div", { staticClass: "div-break-word" }, [
                                  _c("h2", { staticClass: "text-h5" }, [
                                    _vm._v(_vm._s(notificacao.titulo)),
                                  ]),
                                  _c("h3", { staticClass: "text-body-1" }, [
                                    _vm._v(_vm._s(notificacao.resumo)),
                                  ]),
                                ]),
                                notificacao.arquivos &&
                                notificacao.arquivos.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-2 container-image" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: notificacao.arquivos[0].url,
                                            alt: "imagem-notificação",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                notificacao.dataAvaliacao
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-5" },
                                      [
                                        notificacao.avaliacao
                                          ? _c(
                                              "v-icon",
                                              { attrs: { color: "green" } },
                                              [_vm._v("mdi-thumb-up-outline")]
                                            )
                                          : _c(
                                              "v-icon",
                                              { attrs: { color: "red" } },
                                              [_vm._v("mdi-thumb-down-outline")]
                                            ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "mt-5" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { color: "green", icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickAvaliar(
                                                  notificacao.guid,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-thumb-up-outline"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { color: "red", icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickAvaliar(
                                                  notificacao.guid,
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-thumb-down-outline"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }